function registerFlashCloseButtons() {
    const flashButtons = document.querySelectorAll(".notification .delete");

    for (const flashButton of flashButtons) {
        if (flashButton.parentNode === null) {
            throw new Error("invalid flash button");
        }

        const flash = flashButton.parentNode;

        flash.addEventListener("click", () => {
            if (flash.parentNode === null) {
                throw new Error("invalid flash message");
            }

            flash.parentNode.removeChild(flash);
        });

        // In development, there won't be a web server hooked up to
        // this to render the flash message, so we remove it entirely
        if (process.env.NODE_ENV === "development") {
            if (
                flash.parentNode === null ||
                flash.parentNode.parentNode === null
            ) {
                return;
            }

            console.warn("Disabling flash message");

            // Get the containing <span> element
            const block = flash.parentNode;
            flash.parentNode.parentNode.removeChild(block);
        }
    }
}

function registerNavCollapseButtons() {
    const navbarButtons = document.getElementsByClassName("navbar-burger");

    for (const navbarButton of navbarButtons) {
        navbarButton.addEventListener("click", () => {
            if (
                !(navbarButton instanceof HTMLElement) ||
                !navbarButton.dataset.target
            ) {
                throw new Error("invalid navbar button");
            }

            const target = document.getElementById(navbarButton.dataset.target);

            if (target === null) {
                throw new Error("could not find navbar button target");
            }

            navbarButton.classList.toggle("is-active");
            target.classList.toggle("is-active");
        });
    }
}

document.addEventListener("DOMContentLoaded", () => {
    registerFlashCloseButtons();
    registerNavCollapseButtons();
});

export {};
